.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.standings-user-highlight {
  background-color: rgba(0, 0, 128, 0.2);
}

.green {
  background-color: rgba(0, 128, 0, 0.3);

}

.orange {
  background-color:  rgba(255, 185, 0, 0.3);
}

.red {
  background-color: rgba(255, 0, 0, 0.3);
}
